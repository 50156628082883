// b/lib/config.ts

import SvgJsx from "@/components/templateux/svg/svg-jsx"
import { formatPlural } from "../utils/formatPlural"
import getBlurHash from "../utils/getBlurHash"
import { hasData } from "../utils/hasData"
import { truncate } from "../utils/truncate"

export const locale = () => {
  //TODO DO COUNTRY DETECTION IN THE FUTURE WHEN WE HAVE MULTIPLE LANGUAGES
  return `/en`
}

export const global_warrant_canary = '2023-3-23'


export const postobject =
  [
    {
      type: 'text'
     ,text:'Text Post'
     ,value: '0'
     ,short: 'Text'
     ,action: 'Write a Text Post'
     ,icon: 'document-text-sm'
    },
    {
      type: 'image'
      ,text:'Image Upload'
      ,value: '1'
      ,short: 'Image'
      ,action: 'Upload Image Files'
      ,icon: 'photograph-sm'
    },
    {
      type: 'audio'
      ,text:'Audio Upload'
      ,value: '2'
      ,short: 'Audio'
      ,action: 'Upload Audio Files'
      ,icon: 'musical-note-sm'
    },
    {
      type: 'mic'
      ,text:'Voice Memo'
      ,value: '3'
      ,short: 'Recording'
      ,action: 'Record a Voice Memo'
      ,icon: 'mic-sm'
    },
    {
      type: 'avatarupload'
      ,text:'Avatar Upload'
      ,value: '4'
      ,short: 'Avatar'
      ,action: 'Upload an Avatar'
      ,icon: 'profile-sm'
    },
    {
      type: 'broadcast'
      ,text:'Broadcast'
      ,value: '5'
      ,short: 'Broadcast'
      ,action: 'Broadcast a Live Show'
      ,icon: 'speakerphone-sm'
    },
    {
      type: 'link'
      ,text:'Link'
      ,value: '6'
      ,short: 'Link'
      ,action: 'Post a Link'
      ,icon: 'document-text-sm'
    },
    {
      type: 'alert'
      ,text:'Alert'
      ,value: '7'
      ,short: 'Alert'
      ,action: 'Create an Alert'
      ,icon: 'musical-note-sm'
    },
    {
      type: 'draw'
      ,text:'Draw'
      ,value: '8'
      ,short: 'Draw'
      ,action: 'Draw a picture'
      ,icon: 'pencil-sm'
    },
    {
      type: 'soundboard'
      ,text:'Soundboard'
      ,value: '9'
      ,short: 'Soundboard'
      ,action: 'Create a Soundboard'
      ,icon: 'musical-note-sm'
    },
    {
      type: 'imageai'
      ,text:'Image Generation'
      ,value: '10'
      ,short: 'Image'
      ,action: 'Generate Images'
      ,icon: 'photograph-sm'
    },
    {
      type: 'anniversary'
      ,text:'Anniversary'
      ,value: '11'
      ,short: 'anniversary'
      ,action: 'Set an Anniversary'
      ,icon: 'cake-sm'
    },
    {
      type: 'event'
      ,text:'Event'
      ,value: '12'
      ,short: 'Event'
      ,action: 'Create an Event'
      ,icon: 'clock-sm'
    },
  ]

export const lookupPost = (lookup,value=-1) => {

  let output = postobject.find((m)=> {
    if (value > -1) {
      return m.value?.toString() == value?.toString()
    } else {
      return m.type?.toString() == lookup?.toString()
    }
  })

  return output || postobject.find((m)=> {return m.value?.toString() == "0"}) //text default

}


export const regex_patterns = ({
  discord: /^[^#]{2,32}#\d{4}$/
})

export const support_messages = ({
   data_error: `Something went wrong fetching data. Please refresh the page and if this error persists, please contact ${process.env.NEXT_PUBLIC_EMAIL_SUPPORT}.`
  ,loading_short: `Loading...`
  ,loading_alert: `Data is still loading...`
  ,missing_info: `Our apologies: We are missing some information here. Please email us at ${process.env.NEXT_PUBLIC_EMAIL_SUPPORT} to let us know so we can fix it.`
})

export const marketing = ({
  cta: `Join us!`
 ,cta_member: `Go to my profile`
 ,cta_secondary: `Broadcast`
 ,cta_loading: `Broadcast`
})

export const user_types = [
  ['Guest','guest']
 ,['Co-host','cohost']
 ,['Production crew','crew']
]

export const table_type = [
  ,["broadcast","a Broadcast"]
]

export const storage_data = ({
  broadcast: [
  'draftkey'
  ,'drafttime'
  ,'broadcast_title'
]


})

export const blurexamples =
  [
    'cHF5]+Yk^6@-5b,1@[or[k};FxngjMFxS#'
  ]

export const omitArrayItemsWithMatchingKeyValuePair = (key, value, arr) => arr.filter(i => i[key] !== value)

export const user_navlist = (user_name) => [
   `/${user_name?.toLowerCase()}`
  //,`/${user_name?.toLowerCase()}/chat`
  ,`/${user_name?.toLowerCase()}/following`
  ,`/${user_name?.toLowerCase()}/followers`
  // ,`/${user_name?.toLowerCase()}/clips`
  // ,`/${user_name?.toLowerCase()}/showreel`
  // ,`/${user_name?.toLowerCase()}/schedule`
  ,`/${user_name?.toLowerCase()}/posts`
  ,`/${user_name?.toLowerCase()}/audio`
]

export const user_seo_navlist = (user_name) => [
  `/${user_name?.toLowerCase()}`
 ,`/${user_name?.toLowerCase()}/audio`
 ,`/${user_name?.toLowerCase()}/following`
]

export const block_array = [ //description, db_id,
     ["indefinite block","0","ban"]
    ,["5 minute timeout","1","5 min"]
    ,["15 minute timeout","2","15 min"]
    ,["1 hour timeout","3","1 hour"]
    ,["1 day timeout","4","1 day"]
    ,["1 week timeout","5","1 week"]
    ,["1 month timeout","6","1 mo"]
]


// return block_array in seconds
export const block_array_seconds = (n) => {
  if (n == "0") return 0
  if (n == "1") return 300
  if (n == "2") return 900
  if (n == "3") return 3600
  if (n == "4") return 86400
  if (n == "5") return 604800
  if (n == "6") return 2592000
  return 0
}


export const config = ({
       itemsperpage: 12  //how many items to show per page
      ,itemsperdashboard: 3 //how many items appear on dashboard views
      ,newsperdashboard: 3 //how many news items appear on dashboard views
      ,maxitemsperpage: 500 //what's the max number we can show when a page isn't specified?
      ,maxrecords: 20000 //what's the absolute max number of records we can return
      ,pageblocks:5 //controls number of prev/next page numbers. should be an odd #
      ,foldersm:1000  //part of AWS file structure
      ,folderlg:1000000 //part of AWS file structure
      ,saltrounds:10 //used for bcrypt password encryption salt generation
      ,tokenexpiration:2592000 // 30 days in seconds (60*60*24*30),
      ,cookietoken:'usertoken' //name of the cookie where user pref data is stored,
      ,securityurl:`${process.env.NEXT_PUBLIC_SERVER_WEBSITE}/unauthorized` //the url to redirect someone who is on a page they shouldn't be at.
      ,itemsort:'date' //sort method
      ,itemdir:'down' //sort direction
      ,itemview:'list' //item view
      ,storage_free:10000000 //10mb storage for free channels
      ,storage_premium:200000000 //200mb storage for premium channels
      ,wizard_min_width:450 //pixel width for wizard forms before it minimizes some ui items
      ,min_username_length:3
      ,max_username_length:17
      ,username_cooldown: 14 //days in between you can change your username
      ,min_password_length:8
      ,max_password_length:32
      ,max_avatar_size:100000 //(100k)
      ,maxnotificationspersqlcall: 1000 //used in the notifications API to break up loops
      ,max_retries:5 //max number of auto-retries when encountering an error
      ,retry_interval:5000 //5 sec = number of seconds between auto-retries when encountering an error
      ,settings: {
        drafts_message: `Discard unsaved changes?`
      }
      ,profile: {
         stream_interval:25000 //25 seconds polling
        ,stream_interval_host:10000 //10 seconds polling
        ,stream_interval_user:300000 //300 seconds polling
        ,listen_interval:10000 //10 seconds polling
        ,stats_interval:30000 //30 seconds polling
      }
      ,broadcast: {
         title: 'Web Broadcaster'
        ,app_base_url: `/app/broadcaster`
        ,app_base_scheduler: `/app/scheduler`
        ,close_warning: `Are you sure you want to close the broadcaster?`
        ,listener_ping: 30000 //in seconds (update this in mysql broadcast_listener_select as well for consistency)
        ,width: 450
        ,height: 680
        ,height_iframe: 360
        ,mp3_id: process.env.NEXT_PUBLIC_NODE_ENV == 'development' 
        ? 229
        : 642 //this was the id we had when we started activating mp3s.
      }
      ,schedule : {
        description_snippet: 100 //snippet size before truncating
      }
      ,tip_jar: {
         text: 'Tip Jar'
        ,shake_interval: 300 //seconds of shaking after a stream ends
        ,maxfree: 1 //how many support methods can a user list (free)
        ,maxcontributor: 10 //how many support methods can a user list (contributor)
      }
      ,avatar: {
         blurdataurl: `data:image/jpeg;base64,${getBlurHash()}`
        ,url_missing: '/images/chat/avatar-missing.png'
        ,url_loading: '/images/chat/avatar-missing.png'
        ,max_filesize: 1 //in mb
      }
      , meta: {
           maxtitle: 60 //max chars allowed for a meta title
          ,maxdescription: 160 //max chars allowed for a meta description
      }
      , showreel: {
        featured: {
           totaldisplayed: 3 //total to show on the homepage
          ,minimumtime: 3600 //one hour
        }
      }
      , staff: {
        maxmods: 10 //how many moderators a chatroom can have
      }
      ,websockets: {
        chat: {
           public: `chatroom-`
          ,private: `private-`
        },
        listen: `listen-v2-`,
        global: `global-v2`
      }
      ,chat: {
         maxheaderusers: 10 //how many users show up in the header
        ,actioninterval:   500000 //how many microseconds (eg. half a second) between repeat action insertions
        ,messageinterval:  500 //how many milliseconds (eg. half a second) between repeat message insertions
        ,messageheaderinterval: 300000 //how many milliseconds (eg. 5 minutes) between new headers in chat from same sender
        ,floodtimeoutinterval: 15000 //how many milliseconds (eg. 5 minutes) between repeat message insertions
        ,botdefaultinterval: 1500 //how many milliseconds (eg. 5 minutes) before bot replies (default, can be overriden)
        ,announceinterval: 10800000 //how many milliseconds (eg 3 hours) between server announcements (enter/leave) about the user
        ,maxunseenmessages: 20 //how many alerts to track before ignoring new ones (for performance reasons)
        ,maxgroupmessageseconds:60 //how many seconds between posts to link them
        ,displaymessages: 50 //number of messages to show in the chat window
        ,displaymessagesmaxbuffer: 200 //number of max messages to keep in buffer while looking at history
        ,displaymessageshistory: 100 //how many chat messages to show in chat history
        ,minchatheight: 80
        ,minchatwidth: 300
        ,chatruleslength: 512 // how long the chat message can be.
        ,chattypingpause: 50 //how long to wait before unpausing chat while typing (in 1/10th seconds eg. 50=5 seconds)
        ,maxmessagelength: 2048 //what's the longest message size allowed?
        ,flair: {
           maxtags: 10
          ,maxusers:200
          ,maxtaglength: 25
        }
        ,filter: {
           maxwordlength: 32
          ,maxaltlength: 512
          ,maxentries: 128
        }
        ,bot: {
          maxwordlength: 64
         ,maxaltlength: 2000
         ,maxentries: 256
         ,default_name: 'HelpBot'
         ,default_image: `/images/bot/bevbot.png`
        }
        ,replayoffset: {
          sm: 210 
          ,lg: 210 
          ,offline_extra: 117
        }
        ,windowoffset: {
           sm: 268 
          ,lg: 268 
          ,offline_extra: 117
          ,fullscreen: 144
          ,width: {
            sm: 32,
            lg: 72
          },
          panelwidth: 192,
          minmessagewidth: 340
        }
        ,raid_interval: 300 //seconds of raiding redirection
        ,emoji: '🌵' //default chat emoji
        ,emoji_text: 'cactus' //default chat emoji text
        
        ,messages_interval: 1000 //speed of chat data pulls
        ,users_interval: 5000 //speed of users in chat data pulls
        ,timeout: {
           timeout_0: {name: 'forever', status: 0}
          ,timeout_1: {name: '5 minutes', status: 1}
          ,timeout_2: {name: '15 minutes', status: 2}
          ,timeout_3: {name: '1 hour', status: 3}
          ,timeout_4: {name: '1 day', status: 4}
          ,timeout_5: {name: '1 month', status: 5}
          ,timeout_6: {name: '30 days', status: 6}
        }
        ,uploads: {
          max_files:1
        }
       
      }
      ,gate: {
        windowheight: 360,
        defaultmessage: `To access it, kindly consider becoming a contributor.`,
        defaultmessageowner: `The owner of this account will need to become a contributor to enable this.`
      }
      ,post: {
         min_files:1
        ,max_files: 1
        ,max_duration: 10 //seconds
        ,audio: {
          max_filesize: 200 //in mb
        }
        ,image: {
          max_filesize: 5 //in mb
          ,max_height: 1024 //in pixels
          ,max_width: 1024  //in pixels
        }
        ,mic: {
          max_filesize: 20 //in mb
        }
        ,comment: {
          interval:250000 //how many microseconds (eg. half a second) between repeat message insertions
        }
        
      }
      ,soundboard: {
        maxtitle: 44
      }
      ,draw: {
        app_base_url: `/app/draw`
        ,title: `Draw`
        ,chatwidth:"600" 
        ,chatheight:"400" 
        ,bgcolor:'#ffffff'
      }
      ,upload: {
        max_file_size: 1048576 //1MiB
        ,droppanehelp: `Click / Drag & drop / Hover & paste clipboard`
      }
      ,notifications_interval: {
        direct: 60    // 1 hour
       ,discord: 480 //8 hours
      }
      ,bumpers: {
        max_duration: 30 //seconds
      }
})

// Pixel GIF code adapted from https://stackoverflow.com/a/33919020/266535
const keyStr =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/='

const triplet = (e1, e2, e3) =>
  keyStr.charAt(e1 >> 2) +
  keyStr.charAt(((e1 & 3) << 4) | (e2 >> 4)) +
  keyStr.charAt(((e2 & 15) << 2) | (e3 >> 6)) +
  keyStr.charAt(e3 & 63)

export const rgbDataURL = (r, g, b) =>
  `data:image/gif;base64,R0lGODlhAQABAPAA${
    triplet(0, r, g) + triplet(b, 255, 255)
  }/yH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==`

export const phone_info = ({
  phone_countryarray: []
})

export const time = ({
   hours: ['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23']
  ,minutes: ['00','05','10','15','20','25','30','35','40','45','50','55']
})

export const user_settings = ({
  phone_countryarray: [
     [`1`,`USA (+1)`]
    ,[`2`,`test`]
    ,[`3`,`test3`]
  ]
  , demographicarray: [
     [`listener`,`listener - I listen to shows or podcasts but do not usually participate`]
    ,[`chatter`,`participant - I listen to shows and enjoy commenting in show chats`]
    ,[`broadcaster`,`broadcaster - I have my own show or podcast`]
    ,[`other`,`other`]
  ]
  ,planarray: [
     `get notifications about shows I enjoy`
    ,`track updates about shows I enjoy`
    ,`building an audience for my shows`
    ,`all of the above`
    ,`other`
  ]
  ,prefsarray: [
     [`{"view":"cards"}`,`Cards view`]
    ,[`{"view":"list"}`,`List view`]
  ]
  ,bol_notification_bitarray_host_array: [
    [1,`Receive everything related to the hosts I follow, including show alerts.`]
    //,[0,`Receive nothing, ever (except emergencies).`]
   ,[2,`Customize which notifications I receive:`]
  ] 
  ,bol_notification_bitarray_site_array: [
    [1,`Receive everything site related, including activity notifications and occasional updates.`]
    //,[0,`Receive nothing, ever (except emergencies).`]
   ,[2,`Customize which notifications I receive:`]
  ]   
  ,bol_chat_image_array: [
     [1,`Yes. Enable all image types in chat`]
    ,[0,`No. Disable all image types in chat.`]
    ,[2,`Some. Customize which image types are allowed in chat.`]
  ]   

  ,menuarray: [ 
    {
         icon:"profile-sm"
        ,type:"username"
        ,title:"Update username"
        ,short:'Username'
        ,href:'/app/settings/?panel=username'
    }
    ,{
         icon:"lock-closed-sm"
        ,type:"password"
        ,title:"Update password"
        ,short:'Password'
        ,href:'/app/settings/?panel=password'
        ,description:``
    }
      ,{
         icon:"profile-sm"
        ,type:"useravatar"
        ,title:"Avatar"
        ,short:"Avatar"
        ,href:'/app/settings/?panel=useravatar'
        ,description:`Choose an image avatar`
    }
    ,{
         icon:"profile-sm"
        ,type:"userprofile"
        ,title:"User profile"
        ,short:'Profile'
        ,href:'/app/settings/?panel=userprofile'
        ,description:`Update your profile page`
    }
    ,{
         icon:"profile-sm"
        ,type:"userstream"
        ,title:"Stream info"
        ,short:'Stream'
        ,href:'/app/settings/?panel=userstream'
        ,description:`Update your stream settings`
    }
    ,{
         icon:"adjustments-sm"
        ,type:"usernotifications"
        ,title:"User Preferences"
        ,short:"Preferences"
        ,href:'/app/settings/?panel=usernotifications'
        ,description:`Adjust your preferences, including email preferences and page display preferences.`
    }
    ,{
      icon:"collection-sm"
     ,type:"userclips"
     ,title:"Playlists"
     ,short:"Playlists"
     ,href:'/app/settings/?panel=userclips'
     ,description:`Import specific playlists from YouTube.`
 }
    
  ]
})

export const tablehelptype = (table) => {
  if (table=='broadcast') return 'content';
  if (table=='showreel') return 'content';
  if (table=='audio') return 'content';
  if (table=='user_schedule') return 'content';
  if (table=='post') return 'content';
  if (table=='upload') return 'content';
  return table
}

export const chatmenu = ({
  menuarray: {
    all: {
      title: 'All Messages'
      ,short: 'All'
      ,admin: 0
      ,public: 1
      
    }
    ,dm: {
      title: 'Private Messages'
      ,short: 'Private'
      ,admin: 0
      ,public: 0
    }
    ,save: {
      title: 'Saved Messages'
      ,short: 'Saved'
      ,admin: 0
      ,public: 0
    }
    ,report: {
      title: 'Reported Messages'
      ,short: 'Reported'
      ,admin: 1
      ,public: 0
      ,icon: 'lock-closed-sm'
    }
  }
})


  export const settings_layer_submenu = (user_name) => ({
    menuarray: { 
        personal: {
          icon:"settings"
          ,type:["personal"]
          ,short:'Personal'
          ,href:`/${user_name?.toLowerCase()}/settings`
          ,status:1
          ,mobile: '⚙️ Personal Settings'
          ,submenu:0
          ,helptype: 'personal'
        }
        ,profile: {
          icon:"profile"
          ,type:["profile"]
          ,short:'Profile'
          ,href:`/${user_name?.toLowerCase()}/settings/profile`
          ,status:1
          ,mobile: '📓 Profile Settings'
          ,submenu:0
          ,helptype: 'profile'
        }
        ,chat: {
           icon:"chat"
          ,type:["chat"]
          ,short:'Chat'
          ,href:`/${user_name?.toLowerCase()}/settings/chat`
          ,status:1
          ,mobile: '💬 Chat Settings'
          ,submenu:0
          ,helptype: 'chat'
        }
        ,broadcast: {
           icon:"broadcast"
          ,type:["broadcast"]
          ,short:'Broadcast'
          ,href:`/${user_name?.toLowerCase()}/settings/broadcast`
          ,status:1
          ,mobile: '🔊  Broadcast Settings'
          ,submenu:0
          ,helptype: 'broadcast'
        }

        ,content: {
          icon:"content"
         ,type:["content"]
         ,short:'Content'
         ,href:`/${user_name?.toLowerCase()}/settings/content`
         ,status:1
         ,mobile: '🔊  Content Settings'
         ,submenu:0
         ,helptype: 'content'
       }
        ,api: {
           icon:"clock"
          ,type:["api"]
          ,short:'API'
          ,href:`/${user_name?.toLowerCase()}/settings/api`
          ,status:1
          ,mobile: '🛠️ API'
          ,submenu:0
          ,helptype: 'api'
        }
        ,staff: {
          icon:"profile"
         ,type:["staff"]
         ,short:'Staff'
         ,href:`/${user_name?.toLowerCase()}/settings/staff?panel=userstaff`
         ,status:1
         ,mobile: '👩‍👦‍👦 staff'
         ,submenu:0
         ,helptype: 'staff'
       }
     }
    })

export const settings = (user_name) => ({
  menuarray: { 
      icon:"profile"
     ,type:["profile"]
     ,short:'Settings:'
     ,href:`/${user_name?.toLowerCase()}/settings/profile`
     ,status:1
     ,mobile: '📓 Profile Settings'
     ,submenu:1
     ,submenuarray: {
      personal: {
        icon:"settings"
       ,type:["personal"]
       ,short:'Personal Settings'
       ,href:`/${user_name?.toLowerCase()}/settings`
       ,status:1
       ,mobile: '⚙️ Personal Settings'
       ,submenu:0
       ,helptype: 'personal'
      },
       profile: {
         icon:"profile"
        ,type:["profile"]
        ,short:'Profile Settings'
        ,href:`/settings/profile`
        ,status:1
        ,mobile: '📓 Profile Settings'
        ,submenu:0
        ,helptype: 'profile'
      }
      ,chat: {
        icon:"chat"
      ,type:["chat"]
      ,short:'Chat Settings'
      ,href:`/settings/chat`
      ,status:1
      ,mobile: '💬 Chat Settings'
      ,submenu:0
      ,helptype: 'chat'
      }
      ,broadcast: {
        icon:"broadcast"
        ,type:["broadcast"]
        ,short:'Broadcast Settings'
        ,href:`/settings/broadcast`
        ,status:1
        ,mobile: '🔊 Broadcast Settings'
        ,submenu:0
        ,helptype: 'broadcast'
    }
    ,content: {
          icon:"content"
          ,type:["content"]
          ,short:'Content Settings'
          ,href:`/settings/content`
          ,status:1
          ,mobile: '🔊 Content Settings'
          ,submenu:0
          ,helptype: 'content'
      }
      ,api: {
        icon:"clock"
      ,type:["api"]
      ,short:'API Settings'
      ,href:`/settings/api`
      ,status:1
      ,mobile: '🛠️ API'
      ,submenu:0
      ,helptype: 'api'
      }
      ,staff: {
        icon:"profile"
      ,type:["staff"]
      ,short:'Staff Settings'
      ,href:`/settings/staff?panel=userstaff`
      ,status:1
      ,mobile: '👩‍👦‍👦 staff'
      ,submenu:0
      ,helptype: 'staff'
      }
   }

    
  }
})

//TODO - Use this and delete the other list when ready
export const profile_new = ({
  menuarray: { 
    profile: {
         icon:"profile-sm"
        ,type:["profile","posts","audio","about","followers","following","requesters","requesting","ignored"]
        ,short:'Profile'
        ,href:''
        ,status:1
        ,mobile: '🏠  Profile'
        ,submenu: 1
        ,submenuarray: {
          profile: {
              icon:"profile-sm"
             ,type:["profile"]
             ,short:'Profile'
             ,href:''
             ,status:1
             ,mobile: '🏠  Profile'
             ,private:0
             ,helptype: 'profile'
          }
          ,posts: {
              icon:"chat-alt-sm"
            ,type:["posts"]
            ,short:'Posts'
            ,href:'/posts'
            ,status:1
            ,mobile: '💬 Posts'
            ,private:0
            ,helptype: 'content'
            }
            ,audio: {
              icon:"chat-alt-sm"
            ,type:["audio"]
            ,short:'Audio'
            ,href:'/audio'
            ,status:1
            ,mobile: '💬 Audio'
            ,private:0
            ,helptype: 'content'
            }
            ,followers: {
              icon:"user-group-sm"
            ,type:["followers"]
            ,short:'Followers'
            ,href:'/followers'
            ,status:1
            ,mobile: '👩‍👦‍👦 Followers'
            ,private:0
            ,helptype: 'profile'
            }
            ,following: {
              icon:"user-group-sm"
            ,type:["following"]
            ,short:'Following'
            ,href:'/following'
            ,status:1
            ,mobile: '👨‍👧‍👧 Following'
            ,private:0
            ,helptype: 'profile'
            }
            ,requesters: {
              icon:"lock-closed-sm"
            ,type:["requesters"]
            ,short:'Requesters'
            ,href:'/followers/manage/requesters'
            ,status:1
            ,mobile: '🔒 Requesters'
            ,private:1
            ,helptype: 'profile'
            }
            ,requesting: {
              icon:"lock-closed-sm"
            ,type:["requesting"]
            ,short:'Requesting'
            ,href:'/followers/manage/requesting'
            ,status:1
            ,mobile: '🔒 Requesting'
            ,private:1
            ,helptype: 'profile'
            }
            ,declined: {
              icon:"lock-closed-sm"
            ,type:["declined"]
            ,short:'Declined'
            ,href:'/followers/manage/declined'
            ,status:1
            ,mobile: '🔒 Declined'
            ,private:1
            ,helptype: 'profile'
            }
 
          }
    }
   
    ,chat: {
      icon:"chat-alt-sm"
    ,type:["chat","chathistory"]
    ,short:'Chat'
    ,href:'/chat'
    ,status:1
    ,mobile: '💬 Chat'
    ,submenu: 1
    ,helptype: 'chat'
    ,submenuarray: {
        chat: {
          icon:"chat-alt-sm"
        ,type:["chat"]
        ,short:'Chat'
        ,href:'/chat'
        ,status:1
        ,mobile: '💬 Live Chat'
        ,private:0
        ,helptype: 'chat'
        }
        ,chathistory: {
            icon:"lock-closed-sm"
          ,type:["chathistory"]
          ,short:'History'
          ,href:'/chat/history'
          ,status:1
          ,mobile: '🔒 Chat History'
          ,private:0
          ,helptype: 'chat'
        }
       
      }
      
    }
    
    ,showreel: {
      icon:"film-sm"
    ,type:["clips","schedule"]
    ,short:'Schedule'
    ,href:'/schedule'
    ,status:1
    ,mobile: '📆 Schedule'
    ,submenu: 1
    ,helptype: 'content'
    ,submenuarray: {
      
      // showreel: {
      //   icon:"film-sm"
      // ,type:["showreel"]
      // ,short:'Showreel'
      // ,href:'/showreel'
      // ,status:1
      // ,mobile: '📼 Showreel'
      // ,private:0
      // ,helptype: 'content'
      // }
        clips: {
            icon:"film-sm"
          ,type:["clips"]
          ,short:'Clips'
          ,href:'/clips'
          ,status:1
          ,mobile: '🎞️ Clips'
          ,private:0
          ,helptype: 'content'
        }
        ,schedule: {
          icon:"clock-sm"
        ,type:["schedule"]
        ,short:'Schedule'
        ,href:'/schedule'
        ,status:1
        ,mobile: '📆 Schedule'
        ,private:0
        ,helptype: 'content'
        }
      }
      
    }

    ,settings: {
        icon:"user-group-sm"
      ,type:[""]
      ,short:'Settings'
      ,href:'/settings/profile'
      ,status:1
      ,mobile: '👩‍👦‍👦 Followers'
      ,submenu: 1
      ,helptype: 'profile'
      ,submenuarray: {
         settings: {
          icon:"lock-closed-sm"
         ,type:["settings"]
         ,short:'Profile Settings'
         ,href:'/settings/profile'
         ,status:1
         ,mobile: '💬 Profile Settings'
         ,private: 1
         ,helptype: 'user'
         ,private_view:1
        }
        
        ,chatsettings: {
          icon:"lock-closed-sm"
        ,type:["chatsettings"]
        ,short:'Chat Settings'
        ,href:'/settings/chat'
        ,status:1
        ,mobile: '🔒 Chat Settings'
        ,private:1
        ,helptype: 'chat'
      }
        ,contentsettings: {
          icon:"lock-closed-sm"
        ,type:["contentsettings"]
        ,short:'Content Settings'
        ,href:'/settings/content'
        ,status:1
        ,mobile: '💬 Content Settings'
        ,private:1
        ,helptype: 'content'
      }
    }
    }
    
  }
})

export const profile = ({
  menuarray: { 
    profile: {
         icon:"profile-sm"
        ,type:["profile","posts","audio","about"]
        ,short:'Profile'
        ,href:''
        ,status:1
        ,mobile: '🏠  Profile'
        ,submenu: 1
        ,submenuarray: {
          profile: {
              icon:"profile-sm"
             ,type:["profile","posts","audio","about"]
             ,short:'Profile'
             ,href:''
             ,status:1
             ,mobile: '🏠  Profile'
             ,private:0
             ,helptype: 'profile'
          }
          // ,posts: {
          //     icon:"chat-alt-sm"
          //   ,type:["posts"]
          //   ,short:'Posts'
          //   ,href:'/posts'
          //   ,status:1
          //   ,mobile: '💬 Posts'
          //   ,private:0
          //   ,helptype: 'content'
          //   }
            ,settings: {
              icon:"lock-closed-sm"
             ,type:["settings"]
             ,short:'Profile Settings'
             ,href:'/settings/profile'
             ,status:1
             ,mobile: '💬 Profile Settings'
             ,private: 1
             ,helptype: 'profile'
             ,private_view:1
            }
          }
    }
   
    ,chat: {
      icon:"chat-alt-sm"
    ,type:["chat","chathistory"]
    ,short:'Chat'
    ,href:'/chat'
    ,status:1
    ,mobile: '💬 Chat'
    ,submenu: 1
    ,helptype: 'chat'
    ,submenuarray: {
        chat: {
          icon:"chat-alt-sm"
        ,type:["chat"]
        ,short:'Chat'
        ,href:'/chat'
        ,status:1
        ,mobile: '💬 Live Chat'
        ,private:0
        ,helptype: 'chat'
        }
        ,chathistory: {
            icon:"lock-closed-sm"
          ,type:["chathistory"]
          ,short:'History'
          ,href:'/chat/history'
          ,status:1
          ,mobile: '🔒 Chat History'
          ,private:0
          ,helptype: 'chat'
        }
        ,chatsettings: {
          icon:"lock-closed-sm"
        ,type:["chatsettings"]
        ,short:'Chat Settings'
        ,href:'/settings/chat'
        ,status:1
        ,mobile: '🔒 Chat Settings'
        ,private:1
        ,helptype: 'chat'
      }
       
      }
      
    }
    ,followers: {
        icon:"user-group-sm"
      ,type:["followers","following","requesters","requesting","ignored"]
      ,short:'Followers'
      ,href:'/followers'
      ,status:1
      ,mobile: '👩‍👦‍👦 Followers'
      ,submenu: 1
      ,helptype: 'profile'
      ,submenuarray: {
          followers: {
            icon:"user-group-sm"
          ,type:["followers"]
          ,short:'Followers'
          ,href:'/followers'
          ,status:1
          ,mobile: '👩‍👦‍👦 Followers'
          ,private:0
          ,helptype: 'profile'
          }
          ,following: {
            icon:"user-group-sm"
          ,type:["following"]
          ,short:'Following'
          ,href:'/following'
          ,status:1
          ,mobile: '👨‍👧‍👧 Following'
          ,private:0
          ,helptype: 'profile'
          }
          ,requesters: {
            icon:"lock-closed-sm"
          ,type:["requesters"]
          ,short:'Requesters'
          ,href:'/followers/manage/requesters'
          ,status:1
          ,mobile: '🔒 Requesters'
          ,private:1
          ,helptype: 'profile'
          }
          ,requesting: {
            icon:"lock-closed-sm"
          ,type:["requesting"]
          ,short:'Requesting'
          ,href:'/followers/manage/requesting'
          ,status:1
          ,mobile: '🔒 Requesting'
          ,private:1
          ,helptype: 'profile'
          }
          ,declined: {
            icon:"lock-closed-sm"
          ,type:["declined"]
          ,short:'Declined'
          ,href:'/followers/manage/declined'
          ,status:1
          ,mobile: '🔒 Declined'
          ,private:1
          ,helptype: 'profile'
          }
        }
    }
    ,showreel: {
      icon:"film-sm"
    ,type:["showreel","clips","schedule"]
    ,short:'Showreel'
    ,href:'/showreel'
    ,status:1
    ,mobile: '📼 Showreel'
    ,submenu: 1
    ,helptype: 'content'
    ,submenuarray: {
        showreel: {
          icon:"film-sm"
        ,type:["showreel"]
        ,short:'Showreel'
        ,href:'/showreel'
        ,status:1
        ,mobile: '📼 Showreel'
        ,private:0
        ,helptype: 'content'
        }
        ,clips: {
            icon:"film-sm"
          ,type:["clips"]
          ,short:'Clips'
          ,href:'/clips'
          ,status:1
          ,mobile: '🎞️ Clips'
          ,private:0
          ,helptype: 'content'
        }
        ,schedule: {
          icon:"clock-sm"
        ,type:["schedule"]
        ,short:'Schedule'
        ,href:'/schedule'
        ,status:1
        ,mobile: '📆 Schedule'
        ,private:0
        ,helptype: 'content'
        }
        ,contentsettings: {
          icon:"lock-closed-sm"
        ,type:["contentsettings"]
        ,short:'Content Settings'
        ,href:'/settings/content'
        ,status:1
        ,mobile: '💬 Content Settings'
        ,private:1
        ,helptype: 'content'
      }
      }
      
    }
  }
})


export const profilemobilelist = [
  {
       name: 'Profile'
      ,type: 'profile'
      ,url: `/`
      ,private: 0
      ,helptype: 'profile'
      ,icon: 'home-sm'
      ,iconclass: 'group-hover:animate-wiggle'
      ,border: 0
      ,behavior: 'link'
  }
  ,
  {
    name: 'Profile Settings'
   ,type: 'settings'
   ,url: `/settings/profile`
   ,private: 1
   ,helptype: 'profile'
   ,icon: 'settings-sm'
   ,iconclass: 'group-hover:animate-spin'
   ,border: 0
   ,behavior: 'link'
  }
  ,
  {
    name: 'Posts'
   ,type: 'posts'
   ,url: `/posts`
   ,private: 0
   ,helptype: 'content'
   ,icon: 'chat-alt-sm'
   ,iconclass: 'group-hover:animate-wiggle'
   ,border: 0
   ,behavior: 'link'
  }
  ,
  {
    name: 'Audio'
   ,type: 'audio'
   ,url: `/audio`
   ,private: 0
   ,helptype: 'content'
   ,icon: 'chat-alt-sm'
   ,iconclass: 'group-hover:animate-wiggle'
   ,border: 0
   ,behavior: 'link'
  }
  ,
  {
    name: 'Live Chat'
   ,type: 'chat'
   ,url: `/chat`
   ,private: 0
   ,helptype: 'chat'
   ,icon: 'chat-alt-sm'
   ,iconclass: 'group-hover:animate-wiggle'
   ,border: 0
   ,behavior: 'link'
  }
    ,
  {
    name: 'Chat History'
   ,type: 'chathistory'
   ,url: `/chat/history`
   ,private: 0
   ,helptype: 'chat'
   ,icon: 'chat-alt-sm'
   ,iconclass: 'group-hover:animate-wiggle'
   ,border: 0
   ,behavior: 'link'
  }
  
  ,
  {
    name: 'Chat Settings'
   ,type: 'settings'
   ,url: `/settings/chat`
   ,private: 1
   ,helptype: 'chat'
   ,icon: 'settings-sm'
   ,iconclass: 'group-hover:animate-spin'
   ,border: 0
   ,behavior: 'link'
  }
  ,
  {
    name: 'Followers'
   ,type: 'followers'
   ,url: `/followers`
   ,private: 0
   ,helptype: 'profile'
   ,icon: 'user-group-sm'
   ,iconclass: 'group-hover:animate-wiggle'
   ,border: 0
   ,behavior: 'link'
  }
  ,
  {
    name: 'Following'
   ,type: 'following'
   ,url: `/following`
   ,private: 0
   ,helptype: 'profile'
   ,icon: 'user-group-sm'
   ,iconclass: 'group-hover:animate-wiggle'
   ,border: 0
   ,behavior: 'link'
  }
  ,
  {
    name: 'Requesters'
   ,type: 'requesters'
   ,url: `/followers/manage/requesters`
   ,private: 1
   ,helptype: 'profile'
   ,icon: 'user-group-sm'
   ,iconclass: 'group-hover:animate-wiggle'
   ,border: 0
   ,behavior: 'link'
  }
  ,
  {
    name: 'Requesting'
   ,type: 'requesting'
   ,url: `/followers/manage/requesting`
   ,private: 1
   ,helptype: 'profile'
   ,icon: 'user-group-sm'
   ,iconclass: 'group-hover:animate-wiggle'
   ,border: 0
   ,behavior: 'link'
  }
  ,
  {
    name: 'Declined'
   ,type: 'declined'
   ,url: `/followers/manage/declined`
   ,private: 1
   ,helptype: 'profile'
   ,icon: 'user-group-sm'
   ,iconclass: 'group-hover:animate-wiggle'
   ,border: 0
   ,behavior: 'link'
  }
  ,
  {
    name: 'Showreel'
   ,type: 'showreel'
   ,url: `/showreel`
   ,private: 0
   ,helptype: 'content'
   ,icon: 'film-sm'
   ,iconclass: 'group-hover:animate-wiggle'
   ,border: 0
   ,behavior: 'link'
  }
  ,
  {
    name: 'Clips'
   ,type: 'clips'
   ,url: `/clips`
   ,private: 0
   ,helptype: 'profile'
   ,icon: 'film-sm'
   ,iconclass: 'group-hover:animate-wiggle'
   ,border: 0
   ,behavior: 'link'
  }
  ,
  {
    name: 'Schedule'
   ,type: 'schedule'
   ,url: `/schedule`
   ,private: 0
   ,helptype: 'content'
   ,icon: 'clock-sm'
   ,iconclass: 'group-hover:animate-spin'
   ,border: 0
   ,behavior: 'link'
  }
  ,
  {
    name: 'Content Settings'
   ,type: 'settings'
   ,url: `/settings/content`
   ,private: 1
   ,helptype: 'content'
   ,icon: 'settings-sm'
   ,iconclass: 'group-hover:animate-spin'
   ,border: 0
   ,behavior: 'link'
  }
]

export const settingsmobilelist_header = (user_name) => [
    {
      name: 'Personal'
      ,type: 'personal'
      ,url: `/app/settings`
      ,private: 1
      ,helptype: 'user'
      ,icon: 'settings-sm'
      ,iconclass: 'group-hover:animate-spin'
      ,border: 0
      ,behavior: 'link'
      ,staff: 0
    },
    {
      name: 'Creator'
      ,type: 'creator'
      ,url: `/${user_name?.toLowerCase()}/settings/profile`
      ,private: 1
      ,helptype: 'profile'
      ,icon: 'settings-sm'
      ,iconclass: 'group-hover:animate-spin'
      ,border: 0
      ,behavior: 'link'
      ,staff: 1
    }
]

export const settingsmobilelist_layers = (user_name) => (
  [
  {
    name: 'Personal'
    ,type: 'Personal'
    ,url: `/${user_name?.toLowerCase()}/settings`
    ,private: 1
    ,helptype: 'personal'
    ,icon: 'settings-sm'
    ,iconclass: 'group-hover:animate-spin'
    ,border: 0
    ,behavior: 'link'
  },
  {
    name: 'Profile'
   ,type: 'profile'
   ,url: `/${user_name?.toLowerCase()}/settings/profile`
   ,private: 1
   ,helptype: 'profile'
   ,icon: 'profile-sm'
   ,iconclass: 'group-hover:animate-wiggle'
   ,border: 0
   ,behavior: 'link'
  }
  ,
  {
    name: 'Chat'
   ,type: 'chat'
   ,url: `/${user_name?.toLowerCase()}/settings/chat`
   ,private: 1
   ,helptype: 'chat'
   ,icon: 'chat-alt-sm'
   ,iconclass: 'group-hover:animate-wiggle'
   ,border: 0
   ,behavior: 'link'
  }
  ,
  {
    name: 'Broadcast'
   ,type: 'broadcast'
   ,url: `/${user_name?.toLowerCase()}/settings/broadcast`
   ,private: 1
   ,helptype: 'broadcast'
   ,icon: 'film-sm'
   ,iconclass: 'group-hover:animate-wiggle'
   ,border: 0
   ,behavior: 'link'
  }
  ,
  {
    name: 'Content'
   ,type: 'content'
   ,url: `/${user_name?.toLowerCase()}/settings/content`
   ,private: 1
   ,helptype: 'content'
   ,icon: 'film-sm'
   ,iconclass: 'group-hover:animate-wiggle'
   ,border: 0
   ,behavior: 'link'
  }
  ,
  {
    name: 'API'
   ,type: 'api'
   ,url: `/${user_name?.toLowerCase()}/settings/api`
   ,private: 1
   ,helptype: 'api'
   ,icon: 'code-sm'
   ,iconclass: 'group-hover:animate-wiggle'
   ,border: 0
   ,behavior: 'link'
  }
  ,
  {
    name: 'Staff'
   ,type: 'staff'
   ,url: `/${user_name?.toLowerCase()}/settings/staff?panel=userstaff`
   ,private: 1
   ,helptype: 'staff'
   ,icon: 'profile-sm'
   ,iconclass: 'group-hover:animate-wiggle'
   ,border: 0
   ,behavior: 'link'
  }
  ]
)

export const settingsmobilelist = (user_name) => [
  {
       name: 'Personal'
      ,type: 'personal'
      ,url: `/app/settings`
      ,private: 1
      ,helptype: 'personal'
      ,icon: 'settings-sm'
      ,iconclass: 'group-hover:animate-spin'
      ,border: 0
      ,behavior: 'link'
  }
  ,
  {
    name: 'Profile'
   ,type: 'profile'
   ,url: `/${user_name?.toLowerCase()}/settings/profile`
   ,private: 1
   ,helptype: 'profile'
   ,icon: 'profile-sm'
   ,iconclass: 'group-hover:animate-wiggle'
   ,border: 0
   ,behavior: 'link'
  }
  ,
  {
    name: 'Chat'
   ,type: 'chat'
   ,url: `/${user_name?.toLowerCase()}/settings/chat`
   ,private: 1
   ,helptype: 'chat'
   ,icon: 'chat-alt-sm'
   ,iconclass: 'group-hover:animate-wiggle'
   ,border: 0
   ,behavior: 'link'
  }
  ,
  {
    name: 'Broadcast'
   ,type: 'broadcast'
   ,url: `/${user_name?.toLowerCase()}/settings/broadcast`
   ,private: 1
   ,helptype: 'broadcast'
   ,icon: 'film-sm'
   ,iconclass: 'group-hover:animate-wiggle'
   ,border: 0
   ,behavior: 'link'
  }
  ,
  {
    name: 'Content'
   ,type: 'content'
   ,url: `/${user_name?.toLowerCase()}/settings/content`
   ,private: 1
   ,helptype: 'content'
   ,icon: 'film-sm'
   ,iconclass: 'group-hover:animate-wiggle'
   ,border: 0
   ,behavior: 'link'
  }
  ,
  {
    name: 'API'
   ,type: 'api'
   ,url: `/${user_name?.toLowerCase()}/settings/api`
   ,private: 1
   ,helptype: 'api'
   ,icon: 'code-sm'
   ,iconclass: 'group-hover:animate-wiggle'
   ,border: 0
   ,behavior: 'link'
  }
  ,
  {
    name: 'Staff'
   ,type: 'staff'
   ,url: `/${user_name?.toLowerCase()}/settings/staff?panel=userstaff`
   ,private: 1
   ,helptype: 'staff'
   ,icon: 'profile-sm'
   ,iconclass: 'group-hover:animate-wiggle'
   ,border: 0
   ,behavior: 'link'
  }
]






export const profileicons = [
   ["Anchor.fm","/images/icons/anchor.png"]
  ,["Apple Podcasts","/images/icons/apple-podcast.png"]
  ,["Discord","/images/icons/discord.png"]
  ,["Facebook","/images/icons/facebook.png"]
  ,["Google Podcasts","/images/icons/google-podcast.png"]
  ,["Instagram","/images/icons/instagram.png"]
  ,["Mixlr","/images/icons/mixlr.png"]
  ,["OnlyFans","/images/icons/onlyfans.png"]
  ,["Patreon","/images/icons/patreon.png"]
  ,["Reddit","/images/icons/reddit.png"]
  ,["Spotify","/images/icons/spotify.png"]
  ,["TikTok","/images/icons/tiktok.png"]
  ,["Twitch","/images/icons/twitch.png"]
  ,["Twitter","/images/icons/twitter.png"]
  ,["YouTube","/images/icons/youtube.png"]
  ,["Other website","/images/icons/web.png"]
]



  export const demo_info = ({
     user_name: 'demo'
    ,user_status: 1
    ,user_id: 0
    ,email: 'email@email.com'
    ,onboarding_key:'demo-onboarding'
    ,demo_message:'DEMO MODE'
  })

  export const permission_status = ({
     guest:     0 //accounts with no permissions (eg. deleted or banned)
    ,member:    1 //non-premium members
    ,basic:   2 //subscriber2
    ,premium: 3 //subscriber3
    ,business:4 //subscriber4
    ,enterprise:10 // future tier
    ,admin: 1000 //admin permissions
    ,message_member_headline: `Members Only feature`
    ,message_premium_headline: `Premium Only feature`
    ,message_premium: `This feature is only available for subscribers. Please upgrade.`
    ,message_thanks: `Thanks for being a subscriber! This feature is unlocked.`
    ,contact_link: `mailto:${process.env.NEXT_PUBLIC_EMAIL_SUPPORT}`
  })

  export const title_format = (string) => {
    let vtitle = `${string} on ${process.env.NEXT_PUBLIC_BRAND_NAME} - ${meta.title_short}`
    return truncate(vtitle,config.meta.maxtitle)
  }

  export const description_format = (desc) => {
    return truncate(desc,config.meta.maxdescription)
  }

  export const meta = ({
     image: `${process.env.NEXT_PUBLIC_SERVER_WEBSITE}${process.env.NEXT_PUBLIC_MARKETING_SEO_LOGO}`
    ,title: process.env.NEXT_PUBLIC_MARKETING_SEO_TITLE
    ,title_short: process.env.NEXT_PUBLIC_MARKETING_HEADLINE
    ,url:`${process.env.NEXT_PUBLIC_SERVER_WEBSITE}`
    ,description: `${process.env.NEXT_PUBLIC_MARKETING_HEADLINE}`
    ,type: `website`
    ,keywords: process.env.NEXT_PUBLIC_MARKETING_SEO_KEYWORDS
    ,color: process.env.NEXT_PUBLIC_MARKETING_SEO_COLOR
    ,category: process.env.NEXT_PUBLIC_MARKETING_SEO_CATEGORY
  })

  export const audio = ({
    intro: {
      url: process.env.NEXT_PUBLIC_AUDIO_BUMPER_INTRO,
      duration: 5
    }
    ,outro: {
      url: process.env.NEXT_PUBLIC_AUDIO_BUMPER_OUTRO,
      duration: 5
    }
  })

  export const tabarray_posts = (user_name) => {
    if (hasData(user_name)) {
      return [
                {
                    text:`Posts`
                    ,value:`posts`
                    ,url: `/${user_name}`
                }
                ,{
                    text:`Audio`
                    ,value:`audio`
                    ,url:`/${user_name}/audio`
                }
              ,{
                text:`Chat`
                ,value:`chat`
                ,url:`/${user_name}/chat`
            }
            ]
    } else {
      return [
                {
                    text:`All Posts`
                    ,value:`posts`
                    ,url: `/posts`
                }
                ,{
                    text:`Audio`
                    ,value:`audio`
                    ,url:`/audio`
                }
            ]
    }
    
  }

  export const tabarray_posts_home = (user_name) => {
    if (hasData(user_name)) {
      return [
                {
                    icon:<SvgJsx 
                              type={'fill'}
                              icon={"chat-alt-sm"}
                              className={`h-5 w-5  `}
                              title={"chat"}
                          />
                    ,text: "Chat"
                    ,value:`chat`
                    ,url: `/${user_name}/chat`
                }
            ]
    } else {
      return [
                {
                    icon:<SvgJsx 
                              type={'fill'}
                              icon={"newspaper-sm"}
                              className={`h-5 w-5  `}
                              title={"posts"}
                          />
                    ,text: "Posts"
                    ,value:`posts`
                    ,url: `/${user_name}`
                }
                ,{
                    icon: <SvgJsx 
                              type={'fill'}
                              icon={"music-note-sm"}
                              className={`h-5 w-5  `}
                              title={"audio"}
                          />
                    ,text: "Audio"
                    ,value:`audio`
                    ,url:`/${user_name}/audio`
                },
            ]
    }
    
  }

  export const tabarray_follow = (user_name) => {
    
      return [
                {
                    text:`${user_name}'s Profile`
                    ,value:`home`
                    ,url: `/${user_name}/posts`
                    ,special: true
                }
               
            ]
  
  }

    export const tabarray_follow_details = (user_name, followers, following) => {
    
      return [
        {
          text:`Back`
          ,value:`home`
          ,url: `/${user_name}/posts`
          ,special: true
      },
               {
                    text:`${followers} ${formatPlural('Follower',followers)}`
                    ,value:`followers`
                    ,url:`/${user_name}/followers`
                }
                ,{
                    text: `${following} Following`
                    ,value:`following`
                    ,url:`/${user_name}/following`
                }
            ]
  
  }


  export const tabarray_follow_manage = (user_name) => {
    
    return [
              {
                  text:`Requesters`
                  ,value:`requesters`
                  ,url:`/${user_name}/settings/profile?panel=managefollowers&type=requesters#managefollowers`
              }
              ,{
                  text:`Requesting`
                  ,value:`requesting`
                  ,url:`/${user_name}/settings/profile?panel=managefollowers&type=requesting#managefollowers`
              }
              ,{
                  text:`Declined`
                  ,value:`declined`
                  ,url:`/${user_name}/settings/profile?panel=managefollowers&type=declined#managefollowers`
              }
          ]

}



  export function tabarray_posts_filter(user_name,isAdmin) {
    if (hasData(user_name)) {
      let tabresponse = [
                {
                    icon:<SvgJsx 
                              type={'fill'}
                              icon={"newspaper-sm"}
                              className={`h-5 w-5  `}
                              title={"posts"}
                          />
                    ,text: "Posts"
                    ,value:`posts`
                    ,url: `/${user_name}`
                }
               
             
               
            ]

       if (isAdmin) {
          tabresponse.push(
            {
              icon:<SvgJsx 
                        type={'fill'}
                        icon={"settings-sm"}
                        className={`h-5 w-5  `}
                        title={"settings"}
                    />
              ,text: "Settings"
              ,value:`settings`
              ,url: `/${user_name}/settings`
            }
          )
       }

        return tabresponse

    } else {
      return [
                {
                  icon:<SvgJsx 
                            type={'fill'}
                            icon={"newspaper-sm"}
                            className={`h-5 w-5  `}
                            title={"posts"}
                        />
                  ,text: "Posts"
                  ,value:`posts`
                  ,url: `/`
              }
            
            ]
    }
    
  }

  export const tabarray_posts_global = (navtype) => {


   
      return [

                {
                  text:`Hot`
                  ,icon: `Hot`
                  ,value:`featured`
                  ,url: `/${navtype}`
                  ,permission: 0
                }

                ,{
                  text:`All`
                  ,icon: `All`
                  ,value:`all`
                  ,url: `/${navtype}/all`
                  ,permission: 0
                }

                ,{
                    text:`For me`
                    ,icon: `For me`
                    ,value:`following`
                    ,url: `/${navtype}/following`
                    ,permission: 1
                }
                
              
            ]
    
  }

  export const tabarray_posts_global_view = () => {
   
    return [
              {
                  text:`Posts`
                  ,value:`posts`
              }
              ,{
                  text:`Audio`
                  ,value:`audio`
              }
            
          ]
  
}

 
  export const message_sfw = ({
    success : [
      `You did it!`
    ],
    greeting : [
      `Hello!`
    ],
    farewell : [
      `Bye!`
    ],
    failure : [
       `Oops!`
    ],
    title: [
       `Founder`
    ],
    pronouns: [],
    noresults: [
       'No results'
    ],
    loading: [
      `Loading...`
   ]
  })

  export const message_nsfw = ({
    success : [
      `Boom shakalaka!`
      ,`Yippee kayay!`
      ,`Boom goes the dynamite!`
      ,`To infinity and beyond!`
      ,`I believed in you all along`
      ,`Yer a wizard, Harry!`
    ],
    greeting : [
      `Ahoy!`,
      `Hallo Matey!`,
      `What's shaking King?`,
      `BOOM!`,
      `Everybody, shhhh!`,
      `Let's get this party started!`
    ],
    farewell : [
      `Bon Voyage!`,
      `Arrivaderci!`,
      `See ya later, crocodile!`,
      `Bye for now!`,
      `OK we can finally have some fun in here!`
    ],
    failure : [
       `Uh oh!`
      ,`Looks like something went wrong :(`
      ,`Well, this is embarassing`
    ],
    title: [
       `Pinmaster`
      ,`Kennel Cleaner`
      ,`Backup Skydiver`
      ,`Baby Scout Master`
      ,`Staff Clown`
      ,`Russian Attache`
      ,`Vegan Leather Model`
      ,`Nap-taker`
      ,`Litterbug`
    ],
    pronouns: [
       `ya dummy`
      ,`liverlips`
      ,`rubberneck`
      ,`ya bean`
      ,`you hobo`
      ,`clown`
      ,`ya maroon`
      ,`ya bum`
    ],
    noresults: [
       'Nothing to see here.'
    ],
    loading: [
      `Dialing numbers`
     ,`Changing caller id to 867-5309`
     ,`Turning on voice changer`
     ,`Adjusting pitch control`
     ,`Taking apart the television`
     ,`Licking the doorknobs`
     ,`Inflating the water tub`
     ,`Bolting in the hammocks`
     ,`Flirting with the front desk`
     ,`Filling my Tesla with gasoline`
     ,`Hiding my wedding ring in the milk aisle`
     ,`Calling in sick to my doctor`
     ,`Putting fish in the ice machine`
     ,`Saying "thanks mom" to the waitress`
     ,`Dropping my space wrench`
     ,`Buying loose floor hair`
     ,`Moving the ice machine into my hotel room`
     ,`Subletting the lobby`
     ,`Sniffing the cabbages`
     ,`Kicking the stroller`
     ,`Setting off fireworks in my room`
     ,`Shaving the goat`
     ,`Sending the baby in an Uber`
     ,`Dinging your car`
     ,`Digging holes in the yard`
     ,`Returning bowling shoes in the drop slot`
     ,`Granting an audience with the pinmaster`
     ,`Gathering up the street chickens`
     ,`Slaughtering my lunch in the bathtub`
     ,`Storing biohazard samples in the minifridge`
     ,`Posting up in the employee lounge`
     ,`[Censored] on account of my daughter`
     ,`Telling off that wife of yours`
     ,`Asking what you're dreaming about?`
     ,`Conferencing in bev bot`
     ,`Conducting a regular old dog autopsy`
     ,`Taking apart the windows`
     ,`Sealing in the biodome`
     ,`Retrieving cash from the drywall`
     ,`Eating a part of the room`
     ,`Asking the managers' blessing to date employees`
     ,`Setting up a dog run in the hallway`
     ,`Hypothetically getting the roof door open`
     ,`Invoking Squatter's Rights on my hotel room`
     ,`Reselling the vending machine`
     ,`Tasting the bathroom sauces`
     ,`Rinsing off the tv detergent`
     ,`Drizzling tire sauce to make it go better`
     ,`Breaking up with my girlfriend`
     ,`Adding meat discs to the pizza`
     ,`Lurking in the bushes`
     ,`Sleeping in the bathtub`
     ,`Telling on the neighbors for holding hands`
     ,`Tootin my harmonica`
     ,`Slapping it on the phone`
     ,`Saying 'I love you mom' to the waiter`
     ,`Printing out my gravy waiver`
     ,`Changing your phone number`
     ,`Laying out in your backyard at 3am`
     ,`Installing satellite dishes on your roof`
     ,`Turning my apartment into a pool`
     ,`Messing with your milk door`
     ,`Excavating under my trailer home`
     ,`Cooking pasta in the jacuzzi`
     ,`Trading in plasma credits`
     ,`Calling in sick to the job interview`
     ,`Making popcorn on the iron`
     ,`Giving your phone number to Carrottop`
     ,`Getting my golf balls off your roof`
     ,`Listing you as dependents on my tax return`
     ,`Climbing in through the wrong window`
     ,`Napping in unlocked cars`
     ,`Plucking out the meat hairs`
     ,`Salting the shower butter`
     ,`Droning over the egg bucket`
     ,`Installing the elevator in your lawn`
     ,`Hitchhiking in your lot`
     ,`Gambling away the hotelroom tv`
     ,`Doing sign language at the blind lady`
     ,`Steaming potatoes in the shower`
     ,`Ironing up some grilled cheese sandwiches`
     ,`Taking extra breakfast sandwiches`
     ,`Digging non-profit holes`
     ,`Setting off fireworks in the room`
     ,`Doing math behind the building`
     ,`Drilling pilot holes in the roof`
     ,`Doing TikTok dances in the lobby`
   ]
  })

  export const message = ({
    success: process.env.NEXT_PUBLIC_NSFW?.toString()=="1"
              ? message_nsfw?.success
              : message_sfw?.success
    ,
    greeting: process.env.NEXT_PUBLIC_NSFW?.toString()=="1"
              ? message_nsfw?.greeting
              : message_sfw?.greeting
    ,
    farewell: process.env.NEXT_PUBLIC_NSFW?.toString()=="1"
              ? message_nsfw?.farewell
              : message_sfw?.farewell
    ,
    failure: process.env.NEXT_PUBLIC_NSFW?.toString()=="1"
              ? message_nsfw?.failure
              : message_sfw?.failure
    ,
    title: process.env.NEXT_PUBLIC_NSFW?.toString()=="1"
              ? message_nsfw?.title
              : message_sfw?.title
    ,
    pronouns: process.env.NEXT_PUBLIC_NSFW?.toString()=="1"
              ? message_nsfw?.pronouns
              : message_sfw?.pronouns
    ,
    noresults: process.env.NEXT_PUBLIC_NSFW?.toString()=="1"
              ? message_nsfw?.noresults
              : message_sfw?.noresults
    ,
    loading: process.env.NEXT_PUBLIC_NSFW?.toString()=="1"
              ? message_nsfw?.loading
              : message_sfw?.loading
  }) 

  export const profilelinksarray = [
    {
       value:'links'
      ,text:'Link'
    }
    ,{
       value:'text'
      ,text:'Text'
    }
    ,{
       value:'header'
      ,text:'Header'
    }
    ,{
      value:'bullet'
     ,text:'Bullet'
   }
    ,{
      value:'spacer'
     ,text:'Spacer'
   }
    ,{
      value:'line'
     ,text:'Line'
   }
  ]


  export const soundboardfieldsarray = [
    {
       value:'sound'
      ,text:'Sound'
    }
    
  //   ,{
  //      value:'header'
  //     ,text:'Header'
  //   }
  //   ,{
  //     value:'spacer'
  //    ,text:'Spacer'
  //  }
    
  ]

  

  export const email = ({
     community_callout: ``
    ,author_name: process.env.NEXT_PUBLIC_BRAND_AUTHOR
    ,new_welcome_subject: `[${process.env.NEXT_PUBLIC_BRAND_NAME}] Registration complete!`
    ,new_welcome_body: (user_name) =>
`Hi ${user_name},

**Welcome to ${process.env.NEXT_PUBLIC_BRAND_NAME}!** 

My name is ${process.env.NEXT_PUBLIC_BRAND_AUTHOR} and I'm the founder of ${process.env.NEXT_PUBLIC_BRAND_NAME}. I'm also your first follower!

I built this site to make it easier for people who love ${meta.category} to find great content creators and likeminded fans in one place.

This site is in early beta, and I am appreciative for any feedback, bug reports, or dad jokes you have. 

Here's your profile URL for future reference:
${process.env.NEXT_PUBLIC_SERVER_WEBSITE}/${user_name?.toLowerCase()}

- Customize notifications from your favorite creators:
${process.env.NEXT_PUBLIC_SERVER_WEBSITE}/${user_name?.toLowerCase()}/settings/user?panel=usernotifications

- Set up your profile to tell other folks about you.
${process.env.NEXT_PUBLIC_SERVER_WEBSITE}/${user_name?.toLowerCase()}/settings/profile?panel=userprofile

- Want to grow your followers and discover great content? Go follow some of the active broadcasters on our homepage to help other people find your account.
${process.env.NEXT_PUBLIC_SERVER_WEBSITE}

This site is built for the benefit of our community, and currently maintained by a really small team of roughly 1, so your feedback and support makes a huge difference!`
,ps: `Want early access to broadcast your own show? Reply to this email and I'll hook you up with instructions.`
,footer:`
   


_______________

**IMPORTANT ${process.env.NEXT_PUBLIC_BRAND_NAME?.toUpperCase()} LINKS**

* [${process.env.NEXT_PUBLIC_BRAND_NAME} homepage](${process.env.NEXT_PUBLIC_SERVER_WEBSITE})
* [F.A.Q.](${process.env.NEXT_PUBLIC_SERVER_WEBSITE}/en/help)
* [Support ${process.env.NEXT_PUBLIC_BRAND_NAME}](${process.env.NEXT_PUBLIC_SERVER_WEBSITE}/contribute)
* [Change your email preferences](${process.env.NEXT_PUBLIC_SERVER_WEBSITE}/app/settings/user?panel=usernotifications)
`
  })

  export const posts_nav = (user_name) => {

    return [ 
      `/${user_name}`
      ,`/${user_name}/posts`
      ,`/${user_name}/audio`
      ,`/posts`
      ,`/posts/all`
      ,`/posts/followed`
      ,`/audio`
      ,`/audio/all`
      ,`/audio/followed`
   ]
  }

  export const menuaccountlist = (user_name) => [
    {
      name: `My Profile`
      ,type: 'profile'
      ,url: `/${user_name?.toLowerCase()}`
      ,internal: '1'
      ,icon: 'profile-sm'
      ,iconclass: 'group-hover:animate-wiggle'
      ,border: 0
      ,behavior: 'link'
    }
    ,{
      name: `Settings`
      ,type: 'settings'
      ,url: `/${user_name?.toLowerCase()}/settings`
      ,internal: '1'
      ,icon: 'settings-sm'
      ,iconclass: ' group-hover:animate-spin'
      ,border: 0
      ,behavior: 'link'
    }
  ]

  
  export const homebarlist = [
  {
      name: 'Directory'
      ,type: 'directory'
    ,url: `/directory`
    ,pre: ''
    ,internal: '1'
    ,icon: 'user-group-sm'
    ,iconclass: 'group-hover:animate-wiggle'
    ,border: 0
    ,behavior: 'link'
  },
{
  name: 'Help'
  ,type: 'help'
  ,url: `${locale()}/help`
  ,internal: '1'
  ,icon: 'help-sm'
  ,iconclass: 'group-hover:animate-wiggle'
  ,border: 1
  ,behavior: 'link'
},
  
  ]

  export const homebarlist_sub = [
    
    {
        name: 'Terms'
      ,url: `${locale()}/terms`
      ,internal: '1'
    }
    ,{
        name: 'Privacy'
      ,url: `${locale()}/privacy`
      ,internal: '1'
    }
  ]
  
  export const footerlist = [
    {
        name: 'Home'
        ,type: 'home'
        ,url: `/`
        ,internal: '1'
        ,icon: 'home-sm'
        ,iconclass: 'group-hover:animate-wiggle'
        ,border: 0
        ,behavior: 'link'
    }
  
    ,{
      name: 'Directory'
      ,type: 'directory'
    ,url: `/directory`
    ,pre: ''
    ,internal: '1'
    ,icon: 'user-group-sm'
    ,iconclass: 'group-hover:animate-wiggle'
    ,border: 0
    ,behavior: 'link'
  }
     
  ]

  

  export const footerlist_sub = [
    {
        name: 'Help'
      ,url: `${locale()}/help`
      ,internal: '1'
    }
    ,{
        name: 'Terms'
      ,url: `${locale()}/terms`
      ,internal: '1'
    }
    ,{
        name: 'Privacy'
      ,url: `${locale()}/privacy`
      ,internal: '1'
    }
  ]

  export const blockactions = [
    {
         action: -1
        ,action_short:'unblock'
        ,action_past: 'unblocked'
        ,js_amount: 'completely'
        ,button_msg: 'Unblock'
        ,active: 1
    }
    ,{
         action: 0
        ,action_short:'block'
        ,action_past: 'blocked'
        ,js_amount: 'indefinitely'
        ,button_msg: 'Block indefinitely'
        ,active: 1
    }
    ,{
         action: 1
        ,action_short:'timeout'
        ,action_past: 'given a timeout'
        ,js_amount: 'for 5 minutes'
        ,button_msg: '5-minute timeout'
        ,active: 1
    }
    ,{
        action: 2
        ,action_short:'timeout'
        ,action_past: 'given a timeout'
        ,js_amount: 'for 15 minutes'
        ,button_msg: '15-minute timeout'
        ,active: 1
    }
    ,{
        action: 3
        ,action_short:'timeout'
        ,action_past: 'given a timeout'
        ,js_amount: 'for 1 hour'
        ,button_msg: '1-hour timeout'
        ,active: 1
    }
    ,{
        action: 4
        ,action_short:'timeout'
        ,action_past: 'given a timeout'
        ,js_amount: 'for 1 day'
        ,button_msg: '1-day timeout'
        ,active: 1
    }
    ,{
        action: 5
        ,action_short:'timeout'
        ,action_past: 'given a timeout'
        ,js_amount: 'for 1 week'
        ,button_msg: '1-week timeout'
        ,active: 1
    }
    ,{
        action: 6
        ,action_short:'timeout'
        ,action_past: 'given a timeout'
        ,js_amount: 'for 30 days'
        ,button_msg: '30-day timeout'
        ,active: 1
    }
  ]


 export const STAFF_PERMS = {
    profile: {  //1 
      name: `Profile`
      ,description: `Staff can help you set up and edit your public-facing profile, including adding youtube clips and bio info. They can also accept follow requests on your behalf, if your account is set to private.` 
    }
    ,chat: { //2 
      name: `Chat`
      ,description: `Staff can review reported messages, remove messages, block / timeout users, change the welcome message and chat emoji. They will be marked as "MOD" in the chatroom.`
    }
    ,broadcast: { //4 
      name: `Broadcast`
      ,description: `Staff can modify live broadcast settings and information, including broadcast mount information, recording settings, and where the stream can be played. This is useful if you have a smart A/V nerd helping with your setup.`
    }
    ,content: { //8 
      name: `Content`
      ,description: `Staff can access, publish and edit showreel information, show schedules, text posts (coming soon) and other forms of content.`
    }
    ,api: { //16
      name: `API`
      ,description: `Staff can use and modify your settings to query our API. This is useful if you are a company with a team of engineers building a product that uses our API.`
    }
    ,staff: { //32
      name: `Staff`
      ,description: `Staff can add, manage and remove other staff members (except the account owner). This is potentially dangerous, so do not give this permission out to just anyone.`
    }
 }

 export const BOT_PERMS = {
  enter: {  //1 
    name: `Enter Chat`
    ,description: `Enable bot announcements when a user enters the channel.` 
  }
  ,leave: { //2 (DISABLED BY DEFAULT)
    name: `Leave Chat`
    ,description: `Enable bot announcements when a user leaves the channel.`
  }
  ,global: { //4
    name: `Global Commands`
    ,description: `Enable public bot replies to global / commands (eg: "/joke"). If disabled, the replies will be sent as a private message instead of to the whole chatroom.`
  }
}

export const CHAT_IMAGE_PERMS = {

    gif: { //1 
      name: `Chat Gifs`
      ,description: `Enable GIF animated image reactions in chat.`
      ,type:`chat images`
    }
    ,upload: { //2
      name: `Chat Uploads`
      ,description: `Enable custom uploads in chat.`
      ,type:`chat images`
    }
    ,draw: { //4
      name: `Chat Drawings`
      ,description: `Enable custom drawings in chat.`
      ,type:`chat images`
    }
    // ,stickers: { //8
    //   name: `Chat Stickers`
    //   ,description: `Allow users to use sticker reactions in chat. [future feature]`
    //   ,type:`chat images`
    // }
    // ,font: { //16
    //   name: `Custom Font Reactions`
    //   ,description: `Allow users to generate special text font reactions in chat. [future feature]`
    //   ,type:`chat images`
    // }
    // ,ai: { //32
    //   name: `Chat Generations`
    //   ,description: `Allow users to generate AI image reactions in chat. [future feature]`
    //   ,type:`chat images`
    // }
}

 export const NOTIFICATION_PERMS = {
  HOST: {
    livehost: { //1 
      name: `Live Show Host`
      ,description: `When a host you follow is live.*`
      ,type:`Host alerts`
    }
    ,content: { //2
      name: `Content is Published`
      ,description: `When a host you follow publishes content (eg. showreels, text-posts and schedules).*`
      ,type:`Host alerts`
    }
    ,liveguest: { //4
      name: `Live Show Guest`
      ,description: `When a host you follow is a guest on another show [coming soon].*`
      ,type:`Host alerts`
    }
    ,amplify: { //8
      name: `Live Amplification`
      ,description: `When a host you follow amplifies another hosts's live show [coming soon].*`
      ,type:`Host alerts`
    }
  }
 ,SITE: {
    follower: { //1 
      name: `Followers`
      ,description: `When you have a new follower.`
      ,type:`Site related`
    }
    ,dm: { //2
      name: `Direct Message`
      ,description: `When you have a new private message. [coming soon]`
      ,type:`Site related`
    }
    ,update: {  //4 
      name: `Updates`
      ,description: `When ${process.env.NEXT_PUBLIC_BRAND_NAME} announces new app features. [coming soon]` 
      ,type:`Site related`
    }
    ,change: {  //8 
      name: `Account Changes`
      ,description: `When a change is made to your ${process.env.NEXT_PUBLIC_BRAND_NAME} account. [coming soon]`  
      ,type:`Site related`
    }
    ,event: {  //16
      name: `Promotions`
      ,description: `When a platform sponsor is helping support ${process.env.NEXT_PUBLIC_BRAND_NAME}. [coming soon]`  
      ,type:`Site related`
    }
  }
}

  export const player_style = (isPlaying) => {
    return {
      active: {
          backgroundColor: isPlaying
                          ? '#0000ff'
                          : '#0000ff'
          
      
      },
      track: {
          backgroundColor: '#111827',
          display: 'flex',
          height: 16,
          width: '100%',
          cursor: 'pointer',
          padding: '0px 0px'
      },
      thumb: {
          width: 24,
          height: 24,
          backgroundColor: !isPlaying
                          ? '#ffffff'
                          : '#ffffff',
          border: '1px solid #a0a0a0'
      },
    }
  }


  export const follower_type = (navtype) => {
     /*
  
  -- THEY ARE FOLLOWING ME (1/default)

  -- I AM FOLLOWING THEM (-1)
  
  -- BEING REQUESTED OF ME (2) 
  
  -- I AM REQUESTING OF THEM (-2)
  
  -- I REJECTED THEM (0)

  */
    if (navtype == 'followers') return '1';
    if (navtype == 'following') return '-1';
    if (navtype == 'requesters') return '2';
    if (navtype == 'requesting') return '-2';
    if (navtype == 'declined') return '0';
    return '1';
  }

  export const usernameRegex = new RegExp("^[a-zA-Z0-9]*$"); //RegExp("^[a-zA-Z0-9.-]*$"); //(?=.{8,})"); //_A-z0-9
/*
^	The password string will start this way
(?=.*[a-z])	The string must contain at least 1 lowercase alphabetical character
(?=.*[A-Z])	The string must contain at least 1 uppercase alphabetical character
(?=.*[0-9])	The string must contain at least 1 numeric character
(?=.*[._-])	- _ 
(?=.{8,})	The string must be eight characters or longer (disabled)
*/

export const reservedNames = {
  keywords: [
     'admin'
    ,'about'
    ,'all'
    ,'app'
    ,'audio'
    ,'bot'
    ,'broadcast'
    ,'channel'
    ,'chat'
    ,'checkout'
    ,'clips'
    ,'collection'
    ,'create'
    ,'delete'
    ,'design'
    ,'directory'
    ,'donate'
    ,'edit'
    ,'en'
    ,'faq'
    ,'followers'
    ,'following'
    ,'help'
    ,'insert'
    ,'legal'
    ,'list'
    ,'login'
    ,'milkbot'
    ,'helpbot'
    ,'mod'
    ,'moderator'
    ,'new'
    ,'news'
    ,'null'
    ,'owner'
    ,'page'
    ,'post'
    ,'posts'
    ,'sceneary'
    ,'scenery'
    ,'pricing'
    ,'reels'
    ,'register'
    ,'roadmap'
    ,'rules'
    ,'schedule'
    ,'schedules'
    ,'script'
    ,'select'
    ,'settings'
    ,'signin'
    ,'sitemap' 
    ,'showreel'
    ,'showreels'
    ,'studio'
    ,'support'
    ,'survey'
    ,'terms'
    ,'undefined'
    ,'update'
    ,'user_name'
    ,'username'
    ,'user'    
  ]
  ,slurs: [//banned words
    ,'nigger'
    ,'nlgger'
    ,'n1gger'
    ,'nlgg3r'
    ,'n1gg3r'
    ,'nggr'
    ,'negro'
    ,'fuck'
    ,'shit'
    ,'cunt'
    ,'kyke'
    ,'faggot'
    ,'wetback'
    ,'chink'
    ,'retard'
    ,'gringo'
    ,'nazi'
    ,'hitler'
  ]
};



//TODO ADD METHOD TO CHECK IF USERNAMES HAVE ANY UNALLOWED WORDS

  //used for stats and survey data
  export const statmetakey = {
    crdate: "Timestamp"
   ,content_url: "Content Url"
   ,utm_source: "UTM Source"
   ,utm_medium: "UTM Medium"
   ,utm_campaign: "UTM Campaign"
   ,utm_content: "UTM Content"
   ,utm_term: "Paid search terms"
   ,fbclid: "Facebook campaign id"
   ,gclid: "Google campaign id"
   ,keywords: "Search Keywords"
   ,keywords_oq: "Search Keywords Intent"
   ,channel: "Channel"
   ,source: "Source"
   ,referrer_url: "Referrer URL"
   ,referrer_domain: "Referrer Domain"
   ,os: "Operating System"
   ,browser: "Browser"
   ,browser_version: "Browser Version"
   ,browser_type: "Browser Type"
   ,screenw: "Screen Width"
   ,screenh: "Screen Height"
   ,country: "Country"
   ,state: "State"
   ,city: "City"
   ,continent: "Continent"
   ,iptype: "User Type"
   ,language: "Language"
   ,guest_email: "Email"
 }

 export const help = {
   feedback: {
    headline: `How was your latest session?` 
   }
}


 //Onboarding configs used in linked tutorial vidoes and product tour
 
 export const onboarding = {
      item1 : {
        title:        'title'
        ,category:     'category'
        ,description:  `description`
        ,video:        'http://play.google.com/embed/XXXXXXXX' 
        ,bgcolor:     'bg-gradient-to-r from-teal-400 to-blue-400'
        ,dotcolor:     'bg-teal-400'
        ,showvideo:    1
      }
      ,item2 : {
        title:        'title'
        ,category:     'category'
        ,description:  `description`
        ,video:        'http://play.google.com/embed/XXXXXXXX' 
        ,bgcolor:     'bg-gradient-to-r from-blue-400 to-red-400'
        ,dotcolor:     'bg-blue-400'
        ,showvideo:    1
      }
      ,item3 : {
        title:        'title'
        ,category:     'category'
        ,description:  `description`
        ,video:        'http://play.google.com/embed/XXXXXXXX' 
        ,bgcolor:     'bg-gradient-to-r from-red-400 to-orange-400'
        ,dotcolor:     'bg-red-400'
        ,showvideo:    1
      }
      ,item4 : {
        title:        'title'
        ,category:     'category'
        ,description:  `description`
        ,video:        'http://play.google.com/embed/XXXXXXXX' 
        ,bgcolor:     'bg-gradient-to-r from-orange-400 to-purple-400'
        ,dotcolor:     'bg-orange-400'
        ,showvideo:    1
      }
      ,item5 : {
        title:        'title'
        ,category:     'category'
        ,description:  `description`
        ,video:        'http://play.google.com/embed/XXXXXXXX' 
        ,bgcolor:     'bg-gradient-to-r from-purple-400 to-green-400'
        ,dotcolor:     'bg-purple-400'
        ,showvideo:    1
      }
      ,item6 : {
        title:        'title'
        ,category:     'category'
        ,description:  `description`
        ,video:        'http://play.google.com/embed/XXXXXXXX' 
        ,bgcolor:      'bg-gradient-to-r from-green-400 to-violet-400'
        ,dotcolor:     'bg-violet-400'
        ,showvideo:    1
      }
      ,item7 : {
        title:        'title'
        ,category:     'category'
        ,description:  `description`
        ,video:        'http://play.google.com/embed/XXXXXXXX' 
        ,bgcolor:     'bg-gradient-to-r from-violet-400 to-teal-400'
        ,dotcolor:     'bg-green-400'
        ,showvideo:    1
      }
      ,item8 : {
        title:        'title'
        ,category:     'category'
        ,description:  `description`
        ,video:        'http://play.google.com/embed/XXXXXXXX' 
        ,bgcolor:     'bg-gradient-to-r from-violet-400 to-teal-400'
        ,dotcolor:     'bg-green-400'
        ,showvideo:    1
      }
  }

  
export const navtype_info = {


  profile: {
    icon: 'profile'
  ,title: 'Profile'
  ,titles: 'Profile'
  ,url: `/`
  }
  
  ,dashboard: {
    icon: 'home'
  ,title: 'Dashboard'
  ,titles: 'Dashboard'
  ,url: `/app/dashboard/`
  }

  ,settings: {
    icon: 'settings'
  ,title: 'Settings'
  ,titles: 'Settings'
  ,url: `/app/settings/`
  }
  
  ,feedback: {
    icon: 'heart'
  ,title: 'Feedback'
  ,titles: 'Feedback'
  ,url: `/en/feedback`
  }
  
  ,broadcast: {
    icon: 'broadcast'
  ,title: 'Broadcast'
  ,titles: 'Broadcasts'
  ,url: `/app/settings/broadcast`
  }
  
}

export const smsMsg = (verify_code,device_type,device_info) => 
`${verify_code} is your ${process.env.NEXT_PUBLIC_BRAND_NAME} verification code to connect your account and receive notifications.

Enter this verification code in your ${process.env.NEXT_PUBLIC_BRAND_NAME} account when prompted. Alternatively, simply visit this link from your browser:
${process.env.NEXT_PUBLIC_SERVER_WEBSITE}/app/settings/verify/${verify_code}?device=${device_type}&info=${device_info}

${device_type=='phone' || device_type=='whatsapp' ? 
`If you did not request this, please reply STOP to block the sender from accidentally adding your number again.` : ''}`



export const verification_device = {
   phone: { name: 'phone number'
           ,method: 'a SMS' 
          , placeholder: 'phone number'}
  ,email: { name: 'email address'
          ,method: 'an email' 
          ,placeholder: 'your@email.com'}
  ,whatsapp:  { name: 'WhatsApp account'
              ,method: 'a WhatsApp message'
              ,placeholder: 'whatasapp number' }
  ,push: { name: 'mobile device'
          ,method: 'a push notification' 
          ,placeholder: ''}
  ,discord: { name: 'discord username'
          ,method: 'a discord direct message' 
          ,placeholder: 'username#0000'}
}
