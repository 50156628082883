

export default function LoadingRotary({
     classSize='h-32 w-32 '
    ,classAnimate='animate-spin-slow'
    ,bgColor='transparent'
    ,centerColor='#111827'
    ,holeColor='#111827'
    ,pointerColor='#ff0000'
    ,dialerColor='#ffffff'
    ,size='large'
    ,title=`${process.env.NEXT_PUBLIC_BRAND_NAME} ${process.env.NEXT_PUBLIC_BRAND_SUPPORTER}`
}) {



    return (<>

                        <div
                            className={`${classSize} relative `}
                        >    



                                    {size=='large' &&
                                    <>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"
                                            className={`h-full w-full absolute `}
                                        >
                                            <title>{process.env.NEXT_PUBLIC_BRAND_NAME} background</title>
                                            <rect width="64" height="64" rx="8.74"  style={{fill: bgColor}}/>
                                        </svg>

                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"
                                            className={`${classAnimate} h-full w-full absolute`}
                                        >
                                            <title>{process.env.NEXT_PUBLIC_BRAND_NAME} dialer</title>
                                            <g>
                                                <circle cx="32" cy="32" r="24.21" style={{fill:dialerColor}}/>
                                                <circle cx="46.56" cy="43.11" r="2.94" style={{fill: holeColor}}/>
                                                <circle cx="39.44" cy="48.72" r="2.94" style={{fill: holeColor}}/>
                                                <circle cx="30.49" cy="50.28" r="2.94" style={{fill: holeColor}}/>
                                                <circle cx="21.91" cy="47.35" r="2.94" style={{fill: holeColor}}/>
                                                <circle cx="15.72" cy="40.56" r="2.94" style={{fill: holeColor}}/>
                                                <circle cx="13.62" cy="31.73" r="2.94" style={{fill: holeColor}}/>
                                                <circle cx="15.96" cy="22.96" r="2.94" style={{fill: holeColor}}/>
                                                <circle cx="22.23" cy="16.4" r="2.94" style={{fill: holeColor}}/>
                                                <circle cx="30.87" cy="13.67" r="2.94" style={{fill: holeColor}}/>
                                                <circle cx="39.79" cy="15.37" r="2.94" style={{fill: holeColor}}/>
                                                <circle cx="46.78" cy="21.14" r="2.94" style={{fill: holeColor}}/>
                                                <circle cx="32" cy="32" r="12.47"  style={{fill: centerColor}} />
                                            </g>
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"
                                            className={`h-full w-full absolute`}
                                        >
                                            <title>{title}</title>
                                            <polygon points="32 32 64 42.32 64 22.3 32 32" style={{fill:pointerColor}}/>
                                        </svg>
                                    </>}

                                        




                                    {size=='64' &&
                                    <>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"
                                            className={`h-full w-full absolute `}
                                        >
                                            <title>{process.env.NEXT_PUBLIC_BRAND_NAME} background</title>
                                            <rect width="64" height="64" rx="8.74"  style={{fill: bgColor}}/>
                                        </svg>

                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"
                                            className={`${classAnimate} h-full w-full absolute`}
                                        >
                                            <title>{process.env.NEXT_PUBLIC_BRAND_NAME} dialer</title>
                                            <g>
                                                <circle cx="32" cy="32" r="24.21" style={{fill:dialerColor}}/>
                                                <circle cx="46.56" cy="43.11" r="2.94" style={{fill: holeColor}}/>
                                                <circle cx="39.44" cy="48.72" r="2.94" style={{fill: holeColor}}/>
                                                <circle cx="30.49" cy="50.28" r="2.94" style={{fill: holeColor}}/>
                                                <circle cx="21.91" cy="47.35" r="2.94" style={{fill: holeColor}}/>
                                                <circle cx="15.72" cy="40.56" r="2.94" style={{fill: holeColor}}/>
                                                <circle cx="13.62" cy="31.73" r="2.94" style={{fill: holeColor}}/>
                                                <circle cx="15.96" cy="22.96" r="2.94" style={{fill: holeColor}}/>
                                                <circle cx="22.23" cy="16.4" r="2.94" style={{fill: holeColor}}/>
                                                <circle cx="30.87" cy="13.67" r="2.94" style={{fill: holeColor}}/>
                                                <circle cx="39.79" cy="15.37" r="2.94" style={{fill: holeColor}}/>
                                                <circle cx="46.78" cy="21.14" r="2.94" style={{fill: holeColor}}/>
                                                <circle cx="32" cy="32" r="12.47"  style={{fill: centerColor}} />
                                            </g>
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"
                                            className={`h-full w-full absolute`}
                                        >
                                            <title>{title}</title>
                                            <polygon points="32 32 64 42.32 64 22.3 32 32" style={{fill:pointerColor}}/>
                                        </svg>
                                    </>}

                                    {size=='32' &&
                                    <>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"
                                            className={`h-full w-full absolute `}
                                        >
                                            <title>{process.env.NEXT_PUBLIC_BRAND_NAME} background</title>
                                            <rect width="32" height="32" rx="4.35" style={{fill: bgColor}}/>
                                        </svg>

                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"
                                            className={`${classAnimate} h-full w-full absolute`}
                                        >
                                            <title>{process.env.NEXT_PUBLIC_BRAND_NAME} dialer</title>
                                            <g>
                                                <circle cx="16" cy="16" r="12.11" style={{fill:dialerColor}}/>
                                                <circle cx="23.28" cy="21.56" r="1.47" style={{fill: holeColor}}/>
                                                <circle cx="19.72" cy="24.36" r="1.47" style={{fill: holeColor}}/>
                                                <circle cx="15.25" cy="25.14" r="1.47" style={{fill: holeColor}}/>
                                                <circle cx="10.95" cy="23.68" r="1.47" style={{fill: holeColor}}/>
                                                <circle cx="7.86" cy="20.28" r="1.47" style={{fill: holeColor}}/>
                                                <circle cx="6.81" cy="15.87" r="1.47" style={{fill: holeColor}}/>
                                                <circle cx="7.98" cy="11.48" r="1.47" style={{fill: holeColor}}/>
                                                <circle cx="11.11" cy="8.2" r="1.47" style={{fill: holeColor}}/>
                                                <circle cx="15.43" cy="6.84" r="1.47" style={{fill: holeColor}}/>
                                                <circle cx="19.9" cy="7.68" r="1.47" style={{fill: holeColor}}/>
                                                <circle cx="23.39" cy="10.57" r="1.47" style={{fill: holeColor}}/>
                                                <circle cx="16" cy="16" r="6.23" style={{fill: centerColor}}/>
                                            </g>
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"
                                            className={`h-full w-full absolute`}
                                        >
                                            <title>{title}</title>
                                            <polygon points="16 16 32 21.16 32 11.15 16 16" style={{fill:pointerColor}}/>
                                        </svg>

                                    </>}

                                       
                        </div>


                                        
                                        
    
    </>)
}